import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { ReactComponent as TwilioLogo } from './twilio-logo.svg';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import videoLogo from './video-logo.png';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

import textLogo from '../../imgs/logo.png';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    background: '#303030',
  },
  twilioLogo: {
    width: '55%',
    display: 'block',
  },
  videoLogo: {
    width: '25%',
    padding: '2.4em 0 2.1em',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '460px',
    padding: '2em',
    background: 'white',
    color: 'black',
  },
  button: {
    color: 'black',
    background: 'white',
    margin: '0.8em 0 0.7em',
    textTransform: 'none',
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  logo: {
    height: '3em',
    marginRight: '0.5em',
  },
  logoText: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: '#333',
  },
  link: {
    cursor: 'pointer',
    color: '#1577be',
    textDecoration: 'underline',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function LoginPage() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <Paper className={classes.paper} elevation={6}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2em' }}>
            <img src={textLogo} className={classes.logo} />
            <span className={classes.logoText}>Somml Health</span>
          </div>
          <div style={{ fontSize: 20, marginBottom: 20, textAlign: 'center' }}>
            <div>Thank you for using Somml</div>
          </div>

          <a href="https://www.sommlapp.com">Click here to login to Somml</a>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}
