import React from 'react';
import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { useHistory } from 'react-router-dom';

import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const history = useHistory();
  const participants = useParticipants();
  const [maxParticipants, setMaxParticipants] = useState(0);

  console.log('ROOM INFO', room);

  useEffect(() => {
    console.log('Participants: current(', participants.length, '), max(', maxParticipants, ')');
    if (participants.length > maxParticipants) {
      setMaxParticipants(participants.length);
    }

    if (participants.length === 0 && maxParticipants > 0) {
      room.disconnect();
      history.replace('/thanks');
    }
  }, [participants.length]);

  return (
    <Tooltip
      title={'End Call'}
      onClick={() => {
        room.disconnect();
        //window.close();
        history.replace('/thanks');
      }}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab} color="primary">
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
